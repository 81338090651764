import { Component, Vue } from "vue-property-decorator";
import { Prop } from "vue-property-decorator";
import { Card } from "@/types/card";
import { Bank } from "@/types/bank";
import { HtmlElementClassName } from "@/types/element";
import {
  canCloseCard,
  canUnfreezeCard,
  canFreezeCard,
  canUnsuspendCard,
} from "@/lib/card";

@Component
export default class CardActions extends Vue {
  @Prop({ type: Object, required: true }) private readonly card!: Card & {
    bank?: Bank;
  };

  private get cardActions() {
    return [
      {
        text: this.$vuetify.lang.t("$vuetify.dashboard.sidebar.transactions"),
        icon: "$transactions",
        iconColor: "#243143",
        className: HtmlElementClassName.cardTransactionsActionButton,
        attrs: {
          to: {
            name: "transactions",
            query: {
              filterCardOwnerEmail: this.card.owner.email,
              filterCardId: this.card.id,
            },
          },
        },
      },
      {
        text: this.$vuetify.lang.t("$vuetify.dashboard.sidebar.statistic"),
        icon: "$pieChart",
        iconColor: "#243143",
        className: HtmlElementClassName.cardStatisticActionButton,
        attrs: {
          to: {
            name: "statistic",
            query: {
              filterCardOwnerEmail: this.card.owner.email,
              filterCardId: this.card.id,
            },
          },
        },
      },
      {
        text: this.$vuetify.lang.t("$vuetify.dashboard.pause_card"),
        icon: "$pause",
        iconColor: "#243143",
        className: HtmlElementClassName.cardPauseActionButton,
        enabled: canFreezeCard({ status: this.card.status }),
        listeners: {
          click: () => {
            this.$emit("freeze:card", this.card.id);
          },
        },
      },
      {
        text: this.$vuetify.lang.t("$vuetify.dashboard.play_card"),
        icon: "$play",
        iconColor: "#243143",
        enabled: canUnfreezeCard({ status: this.card.status }),
        listeners: {
          click: () => {
            this.$emit("unfreeze:card", this.card.id);
          },
        },
      },
      {
        text: this.$vuetify.lang.t("$vuetify.dashboard.unfreeze_card"),
        icon: "$fire",
        iconColor: "#243143",
        enabled: canUnsuspendCard({ status: this.card.status }),
        listeners: {
          click: () => {
            this.$emit("unfreeze:card", this.card.id);
          },
        },
      },
      {
        text: this.$vuetify.lang.t("$vuetify.dashboard.close_card"),
        icon: "$closeB",
        iconColor: "red",
        className: HtmlElementClassName.cardCloseActionButton,
        enabled: canCloseCard({ status: this.card.status }),
        listeners: {
          click: () => {
            this.$emit("close:card", this.card.id);
          },
        },
      },
    ].filter(({ enabled = true }) => enabled);
  }
}
